import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { PageEditorContext } from "../editor";

import { savePage } from "@/services/page";
import { Button } from "@/components/button/button";
import { DropDown } from "@/components/dropdown/dropdown";

import elementsList from "@/utils/elementList.json";

export const TopPanel = () => {
  const { pageId } = useParams();
  const { gridElements, setGridElements, isLoading, setIsLoading } =
    useContext(PageEditorContext);
  const [displayMenuElements, setDisplayMenuElements] = useState(false);

  const handleDragStart = (e, item) => {
    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/plain", item.type);
  };

  const handleUndo = () => {
    setGridElements({ type: "UNDO" });
  };

  const handleRedo = () => {
    setGridElements({ type: "REDO" });
  };

  const handleSavePage = async () => {
    setIsLoading("save");

    await savePage({
      id: pageId,
      elements: gridElements.elements,
    });

    setGridElements({ type: "SAVE" });
    setIsLoading("");
  };

  return (
    <div
      data-animation="entrance-fade"
      className="z-50 my-2 rounded-lg border bg-white/75 w-[-webkit-fill-available] flex py-2 px-2 justify-between items-center gap-2"
    >
      <div className="flex justify-end gap-1">
        <DropDown.Trigger onClose={() => setDisplayMenuElements(false)}>
          <Button
            type="flat"
            onClick={() => setDisplayMenuElements(!displayMenuElements)}
            icon="add"
            size="small"
            isActive={displayMenuElements}
          />
          {displayMenuElements && (
            <DropDown.Popup
              position={{
                x: "left-0",
                y: "top-full",
              }}
            >
              <div
                className="flex flex-col"
                onDragLeave={() => setDisplayMenuElements(false)}
              >
                <div className="bg-teal-50 rounded-md p-2 border-teal-100 border mb-1">
                  <div className="flex gap-2">
                    <span className="mgc_light_line pt-1"></span>
                    <span>Fancy a hint?</span>
                  </div>
                </div>
                {elementsList.map((itemEl) => (
                  <div
                    onDragStart={(e) => handleDragStart(e, itemEl)}
                    draggable="true"
                    className="w-[-webkit-fill-available]"
                    key={itemEl.type}
                  >
                    <Button icon={itemEl.icon} type="dropdown">
                      {itemEl.title}
                    </Button>
                  </div>
                ))}
              </div>
            </DropDown.Popup>
          )}
        </DropDown.Trigger>
        <Button
          type="ghost"
          size="small"
          onClick={handleUndo}
          isDisabled={
            gridElements.historyPointer === 0 ||
            gridElements.history.length === 1
          }
          icon="back_2"
        ></Button>
        <Button
          type="ghost"
          size="small"
          onClick={handleRedo}
          isDisabled={
            gridElements.historyPointer >= gridElements.history.length - 1
          }
          icon="forward_2"
        ></Button>
      </div>
      <div className="flex justify-end gap-2">
        <Button type="ghost" size="small">
          Preview
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={handleSavePage}
          isLoading={isLoading === "save"}
          isDisabled={isLoading === "save" || gridElements.isSaved}
        >
          {!gridElements.isSaved ? "Save" : "Saved"}
        </Button>
      </div>
    </div>
  );
};
